import React, { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  const [reloadApp, setReloadApp] = useState(false);
  const [seatStatus, setSeatStatus] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [is_subscribed, setISubscribed] = useState(true);
  const [is_subscription_expired, setIs_subscription_expired] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [user_id, setUserId] = useState(null);
  useEffect(() => {
    const handleReloadEvent = () => {
      setReloadApp(true);
    };

    document.addEventListener('reloadAppEvent', handleReloadEvent);

    return () => {
      document.removeEventListener('reloadAppEvent', handleReloadEvent);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        reloadApp,
        setReloadApp,
        seatStatus,
        setSeatStatus,
        isLogin,
        setIsLogin,
        isStreaming,
        setIsStreaming,
        is_subscribed,
        setISubscribed,
        is_subscription_expired,
        setIs_subscription_expired,
        isTrialExpired,
        setIsTrialExpired,
        sessionId,
        setSessionId,
        user_id,
        setUserId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
