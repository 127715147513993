export enum StatusEnum {
  Success = 'success',
  Error = 'error',
  Completed = 'Completed',
}

export enum ActionEnum {
  OpenEmailRequest = 'OpenEmailRequest',
  OpenEmailResponse = 'OpenEmailResponse',
  UserFeedbackRequest = 'UserFeedbackRequest',
  SearchEmailRequest = 'SearchEmailRequest',
  SearchEmailAttachmentRequest = 'SearchEmailAttachmentRequest',
  'SearchEmailAttachmentResponse' = 'SearchEmailAttachmentResponse',
  SearchEmailResponse = 'SearchEmailResponse',
  LinkUnlinkEmailRequest = 'LinkUnlinkEmailRequest',
  LinkUnlinkResponse = 'LinkUnlinkResponse',
  LinkedEmailListRequest = 'LinkedEmailListRequest',
  LinkedEmailListResponse = 'LinkedEmailListResponse',
  LinkedEmailDetailRequest = 'LinkedEmailDetailRequest',
  LinkedEmailDetailResponse = 'LinkedEmailDetailResponse',
  GetUserProcessRequest = 'GetUserProcessRequest',
  GetUserProcessResponse = 'GetUserProcessResponse',
  GetUserActivityRequest = 'GetUserActivityRequest',
  GetUserActivityResponse = 'GetUserActivityResponse',
  SearchUserActivityResponse = 'SearchUserActivityResponse',
  SearchUserActivityRequest = 'SearchUserActivityRequest',
  SendEmailAttachment = 'SendEmailAttachment',
  GetReplyRequest = 'GetReplyRequest',
  GetReplyResponse = 'GetReplyResponse',
  MatterHistoryResponse = 'MatterHistoryResponse',
  MatterHistoryResponseChunk = 'MatterHistoryResponseChunk',

  ChatEmailRequest = 'ChatEmailRequest',
  ChatEmailResponse = 'ChatEmailResponse',
  ChatEmailResponseChunk = 'ChatEmailResponseChunk',

  RelevantDocumentResponse = 'RelevantDocumentResponse',

  GetUnsentDocumentAttachmentsRequest = 'GetUnsentDocumentAttachmentsRequest',
  GetUnsentDocumentAttachmentsResponse = 'GetUnsentDocumentAttachmentsResponse',
  UpdateDocumentAttachmentRequest = 'UpdateDocumentAttachmentRequest',
  UpdateDocumentAttachmentResponse = 'UpdateDocumentAttachmentResponse',
  AnnotationTask = 'AnnotationTask',
  SendToAnnotationQueueRequest = 'SendToAnnotationQueueRequest',
  ConvertPdfRequest = 'ConvertPdfRequest',
  RETRIEVAL_EMAIL = 'RETRIEVAL_EMAIL',
  CheckProcessStatus = 'CheckProcessStatus',
  AttachmentResponse = 'AttachmentResponse',
  SignIn = 'SignIn',
  AddMatterReviewActivityRequest = 'AddMatterReviewActivityRequest',
  GetFeatureFlagRequest = 'GetFeatureFlagRequest',
  GetFeatureFlagResponse = 'GetFeatureFlagResponse',
  GetAccountMgmtRequest = 'GetAccountMgmtRequest',
  GetAccountMgmtResponse = 'GetAccountMgmtResponse',
}

export interface BaseRequest {
  action: ActionEnum;
}

export interface BaseResponse {
  action: ActionEnum;
  status: StatusEnum;
  errorMessage?: string | null;
}

export interface MatterHistoryResponse extends BaseResponse {
  summary: string;
  feature_ref_id: string;
  trace_id: string;
}
export enum DocumentType {
  Word = 'Word',
  PDF = 'PDF',
  Excel = 'Excel',
  PowerPoint = 'PowerPoint',
}
export enum ActivityType {
  docRevew = 'document-review',
  docDraft = 'document-draft',
  matterReview = 'matter-review',
  docProposal = 'document-proposal',
  docMemo = 'document-memo',
}

export const SubscriptionSocketMessages = {
  no_subscription: 'no_subscription',
  expired_subscription: 'expired_subscription',
  expired_trial: 'expired_trial',
};
export const PosthogEventsNames = {
  matter_history_tab_opened: 'Matter History Tab Opened',
  matter_history_popup_opened: 'Matter History Popup Opened',
  matter_history_tab_closed: 'Matter History Tab Closed',
  matter_history_feedback: 'Matter History Feedback Submitted',
  qcounsel_query_response: 'QCounsel Query Response Received',
  qcounsel_query_submitted: 'QCounsel Query Submitted',
  qcounsel_file_attached: 'QCounsel File Attached',
  home_tab_opened: 'Home Tab Opened',
  home_tab_closed: 'Home Tab Closed',
  home_activity_clicked: 'Home Tab Activity Clicked',
  home_activity_search: 'Home Tab Activity Searched',
  home_activity_search_response: 'Home Tab Activity Search Response Received',
  email_docs_tab_opened: 'Email&Docs Tab Opened',
  email_docs_tab_closed: 'Email&Docs Tab Closed',
  email_docs_Email__search: 'Email&Docs Tab Email Searched',
  email_docs_document_search: 'Email&Docs Tab Document Searched',
  email_docs_Email_docs_search_response:
    'Email&Docs Tab Search Response Received',
  email_docs_link_unlink_email:
    'Email&Docs Tab Link and Unlink Emails to Matter',
};
