import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import App from './components/App';
import { AppProvider } from '../provider/appProvider';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

const rootElement: HTMLElement = document.getElementById('rootContainer')!;

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: {
    // url_ignorelist can be used on its own, or combined with url_allowlist to further filter which URLs are captured
    element_allowlist: [],
  },
});

Office.onReady(async () => {
  const root = createRoot(rootElement);
  const customTheme = {
    ...webLightTheme,
    fontFamilyBase: "'Arial', Helvetica, sans-serif", // Custom font
  };
  root.render(
    <FluentProvider theme={customTheme}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        client={posthog}
      >
        <AppProvider>
          <App />
        </AppProvider>
      </PostHogProvider>
    </FluentProvider>
  );
});
