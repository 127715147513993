import React, { useRef, useEffect } from 'react';
import { useState } from 'react';

import type { TabListProps } from '@fluentui/react-components';
import {
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  Tooltip,
  shorthands,
} from '@fluentui/react-components';
import {
  HistoryRegular,
  HomeRegular,
  MailTemplateRegular,
  PersonRegular,
} from '@fluentui/react-icons';

import { MatterHistoryTab } from '../components/tab/MatterHistoryTab';
import { EmailDocsTab } from '../components/tab/EmailDocsTab';
import MainHomeTab from '../components/tab/MainHomeTab';
import BackgroundStatus from './BackgroundStatus';
import { useMailboxContent } from '../../provider/MailboxContentProvider';
import { BatteryProgressBar } from 'qanoonisharedpackage';
import { ActionEnum } from '../../model/base';
enum TabValue {
  MatterHistory = 'MatterHistory',
  EmailDocs = 'Email&Docs',
  MainHome = 'MainHome',
}
const convertDate = timestamp => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

const tabContent: Record<TabValue, { icon: JSX.Element; displayName: string }> =
  {
    [TabValue.MainHome]: {
      icon: <HomeRegular />,
      displayName: 'Home',
    },
    [TabValue.MatterHistory]: {
      icon: <HistoryRegular />,
      displayName: 'Matter History',
    },
    [TabValue.EmailDocs]: {
      icon: <MailTemplateRegular />,
      displayName: 'Email & Docs',
    },
  };

export const TabContainer: React.FC<Partial<TabListProps>> = () => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<TabValue>(null);
  const { userOfflineProcess, GetUserProcess, GetAccountMgmt, accountMgmt } =
    useMailboxContent();

  const handleTabChange = (
    _event: SelectTabEvent<HTMLElement>,
    data: SelectTabData
  ): void => {
    setSelectedKey(data.value as TabValue);
    if (isSocketReady) {
      GetUserProcess({
        action: ActionEnum.GetUserProcessRequest,
      });
    }
  };
  const { isSocketReady } = useMailboxContent();
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const accountRef = useRef(null);
  const buttonRef = useRef(null);

  const handleAccountButtonClick = () => {
    setIsAccountOpen(prev => !prev);
    if (!isSocketReady) {
      console.log(
        'account clicked when no connection, clearing tokens to force sign in'
      );
      localStorage.removeItem('q_token');
      localStorage.removeItem('q_refresh');
      localStorage.removeItem('q_id');
      window.location.reload();
    }
  };

  const handleRefresh = () => {
    console.log('refresh app');
    window.location.reload();
  };

  const handleSignOutApp = () => {
    console.log('sign out of app');
    localStorage.removeItem('q_token');
    localStorage.removeItem('q_refresh');
    localStorage.removeItem('q_id');
    GetAccountMgmt({
      action: ActionEnum.GetAccountMgmtRequest,
      account_request: 'sign out of app',
    });

    window.location.reload();
  };

  const handleSignOutAll = () => {
    console.log('sign out of all');
    localStorage.removeItem('q_token');
    localStorage.removeItem('q_refresh');
    localStorage.removeItem('q_id');
    GetAccountMgmt({
      action: ActionEnum.GetAccountMgmtRequest,
      account_request: 'sign out of all',
    });
    window.location.reload();
  };

  const handleClickOutside = event => {
    if (
      accountRef.current &&
      buttonRef.current &&
      !accountRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsAccountOpen(false);
    }
  };

  useEffect(() => {
    if (isAccountOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAccountOpen]);

  useEffect(() => {
    if (isAccountOpen && isSocketReady) {
      GetAccountMgmt({
        action: ActionEnum.GetAccountMgmtRequest,
        account_request: 'account info request',
      });
    }
  }, [isSocketReady, isAccountOpen]);

  useEffect(() => {
    if (accountMgmt !== undefined) {
      console.log('Account Info Response:', accountMgmt.account_email);
    }
  }, [accountMgmt]);

  const process_value = React.useMemo(() => {
    if (userOfflineProcess) {
      if (
        userOfflineProcess.oldest_online == userOfflineProcess.oldest_processed
      ) {
        return 1;
      } else {
        return (
          userOfflineProcess.email_processed / userOfflineProcess.email_total
        );
      }
    } else {
      return null;
    }
  }, [userOfflineProcess]);
  return (
    <div className={styles.container}>
      {true ? (
        <div className={styles.statusCnt}>
          <BackgroundStatus />
          <Tooltip
            content={
              process_value == 1
                ? `Indexing emails is finished up to ${convertDate(userOfflineProcess?.oldest_processed)}`
                : userOfflineProcess?.oldest_processed != null
                  ? `Indexing emails in progress, currently up to ${convertDate(userOfflineProcess?.oldest_processed)}`
                  : 'Indexing emails pending'
            } // Tooltip string
            relationship="label" // Used for accessibility, indicates the element is a label
          >
            <div className={styles.barCnt}>
              <BatteryProgressBar
                value={process_value}
                width={'200px'}
                height={5}
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <BackgroundStatus />
      )}
      <TabList
        defaultSelectedValue={TabValue.MatterHistory}
        size="small"
        className={styles.tabList}
        onTabSelect={(event, data) => handleTabChange(event, data)}
      >
        {Object.entries(tabContent).map(([key, { icon, displayName }]) => (
          <Tab
            icon={React.cloneElement(icon, {
              className:
                selectedKey === key ? styles.selectedTabIcon : styles.tabIcon,
            })}
            key={key}
            value={key as TabValue}
          >
            <div
              className={`${selectedKey === key ? styles.selectedTabLabel : styles.tabLabel}`}
            >
              {displayName}
            </div>
          </Tab>
        ))}
      </TabList>
      <Tooltip content="Account Management" relationship="label">
        <button
          ref={buttonRef}
          className={styles.accountButton}
          style={{ border: 'none' }}
          onClick={handleAccountButtonClick}
        >
          <PersonRegular />
        </button>
      </Tooltip>
      {isAccountOpen && accountMgmt && (
        <div
          ref={accountRef}
          className={styles.accountWindow}
          style={{ border: '1px solid #ccc', borderRadius: '8px' }}
        >
          <div className={styles.accountWindowContent}>
            <div className={styles.accountInfo}>
              <b>{accountMgmt.account_email}</b>
            </div>
            <div className={styles.accountSignOutArea}>
              <Tooltip
                content="This will just sign you out of this app"
                relationship="label"
              >
                <button
                  className={styles.accountSignOutButton}
                  style={{ border: 'none' }}
                  onClick={handleSignOutApp}
                >
                  Sign Out of this App
                </button>
              </Tooltip>
              <Tooltip
                content="This will stop all mailbox processing"
                relationship="label"
              >
                <button
                  className={styles.accountSignOutButton}
                  style={{ border: 'none' }}
                  onClick={handleSignOutAll}
                >
                  Sign Out of all Qanooni
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      <div
        className={styles.socketStatus}
        title={isSocketReady ? 'Connected' : 'Not Connected'}
      >
        <span
          style={{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: isSocketReady ? 'green' : 'red',
          }}
          onClick={handleRefresh}
        />
      </div>
      <div className={styles.tabContent}>
        {(selectedKey === TabValue.MatterHistory || !selectedKey) && (
          <MatterHistoryTab current_tab={selectedKey} />
        )}
        {selectedKey === TabValue.EmailDocs && <EmailDocsTab />}
        {selectedKey === TabValue.MainHome && <MainHomeTab />}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  barCnt: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  statusCnt: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: '8px',
  },
  socketStatus: {
    position: 'absolute',
    top: '0px',
    right: '6px',
    zIndex: 999,
  },
  accountButton: {
    position: 'absolute',
    top: '2px',
    right: '14px',
    zIndex: 999,
    fontSize: '20px',
    paddingRight: '2px',
    paddingLeft: '2px',
    backgroundColor: '#fff',
    color: '#3B3B3B',
    ':hover': {
      cursor: 'pointer',
    },
  },
  accountWindow: {
    position: 'fixed',
    top: '25px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '95%',
    height: '80px',
    alignItems: 'center',
    zIndex: 1000,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    color: '#3B3B3B',
  },
  accountWindowContent: {},
  accountInfo: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '10px',
  },
  accountSignOutArea: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '20px',
  },
  accountSignOutButton: {
    position: 'relative',
    paddingBottom: '1px',
    fontSize: '12px',
    backgroundColor: '#fff',
    color: '#3B3B3B',
    ':hover': {
      cursor: 'pointer',
      ...shorthands.textDecoration('underline'),
    },
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '9px',
    paddingBottom: '12px',
    backgroundColor: '#fff',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#ccc',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)',
  },
  tabIcon: {
    color: '#3B3B3B',
  },
  selectedTabIcon: {
    color: '#0387f5',
  },
  selectedTabLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#0387f5',
  },
  tabLabel: {
    fontSize: '12px',
    color: '#3B3B3B',
  },
  tabContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundColor: 'white',
  },
  button: {
    marginTop: '10px',
  },
});
