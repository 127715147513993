import { BaseRequest, BaseResponse } from './base';
import { EmailAttachment } from './openEmail';

export enum MessageRole {
  User = 'user',
  Assistant = 'assistant',
  Attach = 'attach',
}

export interface ChatMessage {
  id: string;
  role: MessageRole;
  message: string;
  isChunk: boolean;
  attached: string;
  docs?: [string];
  time?: Date;
}

export interface ChatEmailRequest extends BaseRequest {
  thread_id: string;
  messages: ChatMessage[];
  open_email_body?: string;
  attachments?: EmailAttachment[];
  is_regenrate?: boolean;
}

export interface ChatEmailResponse extends BaseResponse {
  thread_id: string;
  response: string;
  docs?: [string];
  is_regenrate?: boolean;
}

export interface ChatEmailResponseChunk extends BaseResponse {
  thread_id: string;
  response: string;
}
